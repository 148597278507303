import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const PopeSeanPaul = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Nardwuar Must Go" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Pope Sean Paul</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. May 22nd, 2024) </p>
        </div>
        <div className="articleBody">
          <p>It’s a bright, bustling early morning in Vatican City.</p>
          <p>
            Hundreds of worshippers from every corner of the globe have gathered
            outside St. Peter’s Basilica. An Igbo friar makes nice with a Maori
            nun. An Argentine man hoists his young son up onto his shoulders so
            that he can see above the crowd. Journalists mount cameras onto
            tripods at the edges of the scene. All are there at the sudden and
            mysterious beckon of the{" "}
            <a
              href="https://en.wikipedia.org/wiki/Holy_See"
              target="_blank"
              rel="noopener noreferrer"
            >
              Holy See
            </a>
            . Outside a nearby cafe,{" "}
            <a
              href="https://shoya.co/shoyaright/vol3/me-you-oldie-goldie"
              target="_blank"
              rel="noopener noreferrer"
            >
              Oldie
            </a>{" "}
            and I monitor the crowd.
          </p>
          <p>
            The news came via mass SMS: <br />
            “His Holiness Pope Leo XIV, Bishop of Rome, Vicar of Christ,
            returned gently to Our Lord one evening ago. The Cardinals have
            gathered to mourn, and to elect his successor in an expedited
            conclave.”
          </p>
          <p>
            Gasps and wails erupt throughout the crowd in waves, first at LTE
            speed, then 4G, then 3G. Finally, the EDGE floodgates open, and
            everyone bawls openly.
          </p>
          <p>
            Oldie takes a sip from his Ashwaganda-infused mushroom tea. I check
            the time on my{" "}
            <a
              href="https://shoya.co/shoyaright/vol1/shoya-casio"
              target="_blank"
              rel="noopener noreferrer"
            >
              Casio
            </a>
            .{" "}
          </p>
          <p>
            The sun moves westward. It’s an hour til sunset now. The crowd has
            grown larger, and yet more somber. Many shed tears quietly, some hug
            their loved ones, others hold their hands up in prayer. Suddenly, a
            voice in the crowd rings out:
          </p>
          <p>
            VOICE:{" "}
            <a
              href="https://it.wikipedia.org/wiki/Fumata_nera_e_fumata_bianca"
              target="_blank"
              rel="noopener noreferrer"
            >
              Fumata
            </a>
            ! Fumata! Smoke! Look, there’s smoke!{" "}
          </p>
          <p>
            Everyone’s eyes dart up toward the chimney connected to the Sistine
            Chapel. Sure enough, there’s a plume of smoke rising. Strangely, the
            color of the smoke is neither white nor black, but a brilliant blend
            of grey, green, and purple. The Igbo Friar and Maori Nun exchange a
            horrified look. Could this really be? Such a thing is only ever
            whispered about, even in the most hallowed halls.{" "}
            <i>
              <a
                href="https://shoya.co/shoyaright/vol2/shoya-borealis/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fumata boreale
              </a>
            </i>
            .
          </p>
          <p>
            The doors of the balcony of St. Peter’s Cathedral swing open. There
            stands Kardinal Offishal dressed in grey-green-purple cardinal’s
            robes. He clears his throat:
          </p>
          <p>
            KARDINAL OFFISHAL: Blessings upon you all. The conclave has ended.
            It is my great honor to introduce to you all, the new Bishop
            of Rome, Vicar of Jesus Christ, Successor of the Prince of the
            Apostles, Supreme Pontiff of the Universal Church, Patriarch of the
            West, Primate of Italy.
          </p>
          <p>He inhales deeply after that mouthful. </p>
          <p>
            KARDINAL OFFISHAL: I present for, the first time: His Holiness, Pope
            Sean Paul!!!!!!
          </p>
          <p>
            Airhorns blare out from a source unseen as Sean Paul, dressed in
            full papal regalia (big ass hat included), steps out onto the
            balcony. His fine white robes glisten with golden accents. The crown
            jewel of his big ass hat is a shimmering grey-green-purple stone.{" "}
          </p>
          <p>
            I can’t help but grin to myself. Oldie puts down his tea and picks
            up his iPhone 37.
          </p>
          <p>
            OLDIE: I’ll get{" "}
            <a
              href="https://shoya.co/shoyaright/vol4/f%C3%BChrer-king-waka"
              target="_blank"
              rel="noopener noreferrer"
            >
              Waka
            </a>{" "}
            on the phone.
          </p>
          <p>
            The airhorns subside, and a hush comes over the crowd. The new pope
            clears his throat, and it somehow gets even more quiet.{" "}
          </p>
          <p>POPE SEAN PAUL: My children…</p>
          <p>
            A thousand or more aspiring tenants of heaven all seemed to hold
            their breath. What will his first words be?
          </p>
          <p>
            POPE SEAN PAUL:{" "}
            <a
              href="https://youtu.be/0zZ2bifb338?si=TMLAnQHxc_nH-Xb8&t=27"
              target="_blank"
              rel="noopener noreferrer"
            >
              SO MI GO SO DEN!!!!!!!!
            </a>
          </p>
          <p>
            These words boom and echo with an otherworldly volume and timbre for
            miles around. A quiet, pregnant pause takes hold, then: the airhorns
            resume, and the crowd breaks out into the loudest cheering and
            applause ever recorded in the Vatican.
          </p>
          <p>
            As the new pope looks out over the cheering crowd, some literally
            jumping for joy, he spots a figure in a black cloak. The person is
            standing dead-center, and is the only perfectly still body in the
            dynamic crowd. Pope Sean Paul reaches for the papal binoculars, (you
            know, like the ones they use at the opera or whatever). Through the
            binoculars, he sees that the face partially covered by the hood of
            the black cloak…….is a mirror staring right back at him.{" "}
          </p>
          <p>
            It’s{" "}
            <a
              href="https://shoya.co/shoyaright/vol2/shoya-borealis/"
              target="_blank"
              rel="noopener noreferrer"
            >
              You
            </a>
            .{" "}
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020 - &infin;</p>
        </div>
      </div>
    </div>
  )
}
export default PopeSeanPaul
